
import { defineComponent } from "vue";
import store from "@/store";
import { GetLastDoublingGameResponse } from "@/types/doubling";

export default defineComponent({
  name: "last-boxes",

  data() {
    return {
      boxRightOpen: require("@/images/boxes/boxes-right-open.png"),
      boxLeftOpen: require("@/images/boxes/boxes-left-open.png"),
      boxRightOpenActive: require("@/images/boxes/right-box-open-active.png"),
      boxLeftOpenActive: require("@/images/boxes/left-box-open-active.png"),
      boxRightOpenKhoja: require("@/images/boxes/box-right-open-khoja.png"),
      boxLeftOpenKhoja: require("@/images/boxes/box-left-open-khoja.png"),
      boxRightOpenActiveKhoja: require("@/images/boxes/box-right-open-khoja.png"),
      boxLeftOpenActiveKhoja: require("@/images/boxes/box-left-open-khoja.png"),
      boxRightOpenKissaho: require("@/images/boxes/kissaho-box-right-open.png"),
      boxLeftOpenKissaho: require("@/images/boxes/kissaho-box-left-open.png"),
      boxRightOpenActiveKissaho: require("@/images/boxes/kissaho-box-right-open-active.png"),
      boxLeftOpenActiveKissaho: require("@/images/boxes/kissaho-box-left-open-active.png"),
      boxRightOpenSilkWay: require("@/images/boxes/silk-way-bag-right-open.png"),
      boxLeftOpenSilkWay: require("@/images/boxes/silk-way-bag-left-open.png"),
      boxRightOpenActiveSilkWay: require("@/images/boxes/silk-way-bag-right-open-active.png"),
      boxLeftOpenActiveSilkWay: require("@/images/boxes/silk-way-bag-left-open-active.png"),
      boxRightOpenPamir: require("@/images/boxes/pamir-rock-right-open.png"),
      boxLeftOpenPamir: require("@/images/boxes/pamir-rock-left-open.png"),
      boxRightOpenActivePamir: require("@/images/boxes/pamir-rock-right-open.png"),
      boxLeftOpenActivePamir: require("@/images/boxes/pamir-rock-left-open.png"),
      boxRightOpenPearl: require("@/images/boxes/box-right-open-pearl.png"),
      boxLeftOpenPearl: require("@/images/boxes/box-left-open-pearl.png"),
      boxRightOpenActivePearl: require("@/images/boxes/box-right-open-pearl.png"),
      boxLeftOpenActivePearl: require("@/images/boxes/box-left-open-pearl.png"),
      boxRightOpenAstro: require("@/images/boxes/box-right-open-astro.png"),
      boxLeftOpenAstro: require("@/images/boxes/box-left-open-astro.png"),
      boxRightOpenActiveAstro: require("@/images/boxes/box-right-open-astro-active.png"),
      boxLeftOpenActiveAstro: require("@/images/boxes/box-left-open-astro-active.png"),
      boxRightOpenMisr: require("@/images/boxes/box-right-open-misr.png"),
      boxLeftOpenMisr: require("@/images/boxes/box-left-open-misr.png"),
      boxRightOpenActiveMisr: require("@/images/boxes/box-right-open-misr-active.png"),
      boxLeftOpenActiveMisr: require("@/images/boxes/box-left-open-misr-active.png"),
    };
  },
  computed: {
    updateLastDoublingGames(): GetLastDoublingGameResponse {
      return store.state.doubling.lastDoublingGames;
    },
    isKhoja(): boolean {
      return store.state.currentGameId === "loto-khoja";
    },
    isKissaho(): boolean {
      return store.state.currentGameId === "loto-kissaho";
    },
    isSilkWay(): boolean {
      return store.state.currentGameId === "loto-silk-way";
    },
    isPamirLegends(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
    isAstro(): boolean {
      return store.state.currentGameId === "loto-astrologer";
    },

    isMisr(): boolean {
      return store.state.currentGameId === "loto-misr";
    },
  },
  methods: {
    currentImage(box: any, index: number) {
      if (
        (box.sign === "right" && box.status === "won") ||
        (box.sign === "left" && box.status === "lost")
      ) {
        if (index === 0) {
          return this.isKhoja
            ? this.boxRightOpenActiveKhoja
            : this.isKissaho
            ? this.boxRightOpenActiveKissaho
            : this.isSilkWay
            ? this.boxRightOpenActiveSilkWay
            : this.isPamirLegends
            ? this.boxRightOpenActivePamir
            : this.isPearl
            ? this.boxRightOpenActivePearl
            : this.isAstro
            ? this.boxRightOpenActiveAstro
            : this.isMisr
            ? this.boxRightOpenActiveMisr
            : this.boxRightOpenActive;
        } else {
          return this.isKhoja
            ? this.boxRightOpenKhoja
            : this.isKissaho
            ? this.boxRightOpenKissaho
            : this.isSilkWay
            ? this.boxRightOpenSilkWay
            : this.isPamirLegends
            ? this.boxRightOpenPamir
            : this.isPearl
            ? this.boxRightOpenPearl
            : this.isAstro
            ? this.boxRightOpenAstro
            : this.isMisr
            ? this.boxRightOpenMisr
            : this.boxRightOpen;
        }
      } else {
        if (index === 0) {
          return this.isKhoja
            ? this.boxLeftOpenActiveKhoja
            : this.isKissaho
            ? this.boxLeftOpenActiveKissaho
            : this.isSilkWay
            ? this.boxLeftOpenActiveSilkWay
            : this.isPamirLegends
            ? this.boxLeftOpenActivePamir
            : this.isPearl
            ? this.boxLeftOpenActivePearl
            : this.isAstro
            ? this.boxLeftOpenActiveAstro
            : this.isMisr
            ? this.boxLeftOpenActiveMisr
            : this.boxLeftOpenActive;
        } else {
          return this.isKhoja
            ? this.boxLeftOpenKhoja
            : this.isKissaho
            ? this.boxLeftOpenKissaho
            : this.isSilkWay
            ? this.boxLeftOpenSilkWay
            : this.isPamirLegends
            ? this.boxLeftOpenPamir
            : this.isPearl
            ? this.boxLeftOpenPearl
            : this.isAstro
            ? this.boxLeftOpenAstro
            : this.isMisr
            ? this.boxLeftOpenMisr
            : this.boxLeftOpen;
        }
      }
    },
  },
});
