import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ea0d68d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "start-screen" }
const _hoisted_2 = { class: "preloader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "progress-bar",
        style: _normalizeStyle({ width: _ctx.progress + '%' })
      }, null, 4),
      _createElementVNode("p", null, _toDisplayString(_ctx.progress) + "%", 1)
    ])
  ]))
}