export const mevahoImgs = [
  require("@/images/backgrounds/bg-mevaho-mobile.jpg"),
  require("@/images/backgrounds/bg-mevaho.jpg"),
  require("@/images/backgrounds/carpet-open.webp"),
  require("@/images/fruits/fruit-0.png"),
  require("@/images/fruits/fruit-1.png"),
  require("@/images/fruits/fruit-2.png"),
  require("@/images/fruits/fruit-3.png"),
  require("@/images/fruits/fruit-4.png"),
  require("@/images/fruits/fruit-5.png"),
  require("@/images/fruits/fruit-6.png"),
  require("@/images/fruits/fruit-7.png"),
];

export const kissahoImgs = [
  require("@/images/backgrounds/bg-kissaho-mobile.webp"),
  require("@/images/backgrounds/bg-kissaho.webp"),
  require("@/images/borders/kissaho-scoreboard-border.png"),
  require("@/images/kissaho/anim-0.webp"),
  require("@/images/kissaho/anim-1.webp"),
  require("@/images/kissaho/anim-2.webp"),
  require("@/images/kissaho/anim-3.webp"),
  require("@/images/kissaho/anim-4.webp"),
  require("@/images/kissaho/anim-5.webp"),
  require("@/images/kissaho/anim-6.webp"),
  require("@/images/kissaho/anim-7.webp"),
  require("@/images/kissaho/anim-8.webp"),
  require("@/images/kissaho/anim-9.webp"),
  require("@/images/kissaho/anim-10.webp"),
  require("@/images/kissaho/anim-11.webp"),
  require("@/images/kissaho/anim-12.webp"),
  require("@/images/kissaho/static-0.webp"),
  require("@/images/kissaho/static-1.webp"),
  require("@/images/kissaho/static-2.webp"),
  require("@/images/kissaho/static-3.webp"),
  require("@/images/kissaho/static-4.webp"),
  require("@/images/kissaho/static-5.webp"),
  require("@/images/kissaho/static-6.webp"),
  require("@/images/kissaho/static-7.webp"),
  require("@/images/kissaho/static-8.webp"),
  require("@/images/kissaho/static-9.webp"),
  require("@/images/kissaho/static-10.webp"),
  require("@/images/kissaho/static-11.webp"),
  require("@/images/kissaho/static-12.webp"),
  require("@/images/backgrounds/carpet-open-kissaho.webp"),
  require("@/images/backgrounds/background-kissaho-bonus.webp"),
];

export const gambusakiImgs = [
  require("@/images/backgrounds/bg-kissaho-mobile.webp"),
  require("@/images/backgrounds/bg-kissaho.webp"),
  require("@/images/borders/kissaho-scoreboard-border.png"),
  require("@/images/beetles/anim-0.webp"),
  require("@/images/beetles/anim-1.webp"),
  require("@/images/beetles/anim-2.webp"),
  require("@/images/beetles/anim-3.webp"),
  require("@/images/beetles/anim-4.webp"),
  require("@/images/beetles/anim-5.webp"),
  require("@/images/beetles/anim-6.webp"),
  require("@/images/beetles/anim-7.webp"),
  require("@/images/beetles/anim-8.webp"),
  require("@/images/beetles/anim-9.webp"),
  require("@/images/beetles/anim-10.webp"),
  require("@/images/beetles/viola.webp"),
  require("@/images/beetles/sing.webp"),
  require("@/images/beetles/sax.webp"),
  require("@/images/beetles/magic.webp"),
  require("@/images/beetles/guitar.webp"),
  require("@/images/beetles/drum.webp"),
  require("@/images/beetles/static-0.webp"),
  require("@/images/beetles/static-1.webp"),
  require("@/images/beetles/static-2.webp"),
  require("@/images/beetles/static-3.webp"),
  require("@/images/beetles/static-4.webp"),
  require("@/images/beetles/static-5.webp"),
  require("@/images/beetles/static-6.webp"),
  require("@/images/beetles/static-7.webp"),
  require("@/images/beetles/static-8.webp"),
  require("@/images/beetles/static-9.webp"),
  require("@/images/beetles/static-10.webp"),
  require("@/images/backgrounds/carpet-open-gold.webp"),
  require("@/images/backgrounds/carpet-open.webp"),
  require("@/images/backgrounds/bonus-gold-container-mobile.webp"),
];

export const khojaImgs = [
  require("@/images/backgrounds/street-khoja-mobile.webp"),
  require("@/images/backgrounds/border-khoja-mobile.png"),
  require("@/images/backgrounds/bg-khoja-mobile.webp"),
  require("@/images/khoja/anim-0.webp"),
  require("@/images/khoja/anim-1.webp"),
  require("@/images/khoja/anim-2.webp"),
  require("@/images/khoja/anim-3.webp"),
  require("@/images/khoja/anim-4.webp"),
  require("@/images/khoja/anim-5.webp"),
  require("@/images/khoja/anim-6.webp"),
  require("@/images/khoja/anim-7.webp"),
  require("@/images/khoja/anim-8.webp"),
  require("@/images/khoja/anim-9.webp"),
  require("@/images/khoja/anim-10.webp"),
  require("@/images/khoja/anim-11.webp"),
  require("@/images/khoja/anim-12.webp"),
  require("@/images/khoja/static-0.webp"),
  require("@/images/khoja/static-1.webp"),
  require("@/images/khoja/static-2.webp"),
  require("@/images/khoja/static-3.webp"),
  require("@/images/khoja/static-4.webp"),
  require("@/images/khoja/static-5.webp"),
  require("@/images/khoja/static-6.webp"),
  require("@/images/khoja/static-7.webp"),
  require("@/images/khoja/static-8.webp"),
  require("@/images/khoja/static-9.webp"),
  require("@/images/khoja/static-10.webp"),
  require("@/images/khoja/static-11.webp"),
  require("@/images/khoja/static-12.webp"),
  require("@/images/backgrounds/carpet-open-khoja.webp"),
  require("@/images/backgrounds/background-khoja-bonus.webp"),
];

export const misrImgs = [
  require("@/images/backgrounds/bg-doubling-misr-mobile.webp"),
  require("@/images/backgrounds/bg-misr-mobile.webp"),
  require("@/images/backgrounds/bg-misr.webp"),
  require("@/images/backgrounds/doubling-misr.webp"),
  require("@/images/misr/anim-0.webp"),
  require("@/images/misr/anim-1.webp"),
  require("@/images/misr/anim-2.webp"),
  require("@/images/misr/anim-3.webp"),
  require("@/images/misr/anim-4.webp"),
  require("@/images/misr/anim-5.webp"),
  require("@/images/misr/anim-6.webp"),
  require("@/images/misr/anim-7.webp"),
  require("@/images/misr/anim-8.webp"),
  require("@/images/misr/anim-9.webp"),
  require("@/images/misr/anim-10.webp"),
  require("@/images/misr/anim-11.webp"),
  require("@/images/misr/anim-12.webp"),
  require("@/images/misr/static-0.webp"),
  require("@/images/misr/static-1.webp"),
  require("@/images/misr/static-2.webp"),
  require("@/images/misr/static-3.webp"),
  require("@/images/misr/static-4.webp"),
  require("@/images/misr/static-5.webp"),
  require("@/images/misr/static-6.webp"),
  require("@/images/misr/static-7.webp"),
  require("@/images/misr/static-8.webp"),
  require("@/images/misr/static-9.webp"),
  require("@/images/misr/static-10.webp"),
  require("@/images/misr/static-11.webp"),
  require("@/images/misr/static-12.webp"),
  require("@/images/backgrounds/carpet-open-misr.webp"),
  require("@/images/backgrounds/background-misr-bonus.webp"),
];
export const pamirImgs = [
  require("@/images/backgrounds/bg-pamir-legends.webp"),
  require("@/images/backgrounds/pamir-legends-bonus-background-night.webp"),
  require("@/images/backgrounds/pamir-legends-stars.webp"),
  require("@/images/backgrounds/bg-pamir-legends.webp"),
  require("@/images/backgrounds/pamir-legends-bonus-background-night.webp"),
  require("@/images/pamir/anim-0.webp"),
  require("@/images/pamir/anim-1.webp"),
  require("@/images/pamir/anim-2.webp"),
  require("@/images/pamir/anim-3.webp"),
  require("@/images/pamir/anim-4.webp"),
  require("@/images/pamir/anim-5.webp"),
  require("@/images/pamir/anim-6.webp"),
  require("@/images/pamir/anim-7.webp"),
  require("@/images/pamir/anim-8.webp"),
  require("@/images/pamir/anim-9.webp"),
  require("@/images/pamir/anim-10.webp"),
  require("@/images/pamir/anim-11.webp"),
  require("@/images/pamir/anim-12.webp"),
  require("@/images/pamir/static-0.webp"),
  require("@/images/pamir/static-1.webp"),
  require("@/images/pamir/static-2.webp"),
  require("@/images/pamir/static-3.webp"),
  require("@/images/pamir/static-4.webp"),
  require("@/images/pamir/static-5.webp"),
  require("@/images/pamir/static-6.webp"),
  require("@/images/pamir/static-7.webp"),
  require("@/images/pamir/static-8.webp"),
  require("@/images/pamir/static-9.webp"),
  require("@/images/pamir/static-10.webp"),
  require("@/images/pamir/static-11.webp"),
  require("@/images/pamir/static-12.webp"),
  require("@/images/backgrounds/carpet-open-pamir-legends.webp"),
  require("@/images/backgrounds/background-pamir-legends-bonus.webp"),
];

export const pearlImgs = [
  require("@/images/backgrounds/bg-pearl.webp"),
  require("@/images/backgrounds/bg-pearl-mobile.webp"),
  require("@/images/backgrounds/doubling-pearl.webp"),
  require("@/images/borders/only-border-pearl.png"),
  require("@/images/borders/only-border-pearl-mobile.png"),
  require("@/images/pearl/anim-0.webp"),
  require("@/images/pearl/anim-1.webp"),
  require("@/images/pearl/anim-2.webp"),
  require("@/images/pearl/anim-3.webp"),
  require("@/images/pearl/anim-4.webp"),
  require("@/images/pearl/anim-5.webp"),
  require("@/images/pearl/anim-6.webp"),
  require("@/images/pearl/anim-7.webp"),
  require("@/images/pearl/anim-8.webp"),
  require("@/images/pearl/anim-9.webp"),
  require("@/images/pearl/anim-10.webp"),
  require("@/images/pearl/anim-11.webp"),
  require("@/images/pearl/anim-12.webp"),
  require("@/images/pearl/static-0.webp"),
  require("@/images/pearl/static-1.webp"),
  require("@/images/pearl/static-2.webp"),
  require("@/images/pearl/static-3.webp"),
  require("@/images/pearl/static-4.webp"),
  require("@/images/pearl/static-5.webp"),
  require("@/images/pearl/static-6.webp"),
  require("@/images/pearl/static-7.webp"),
  require("@/images/pearl/static-8.webp"),
  require("@/images/pearl/static-9.webp"),
  require("@/images/pearl/static-10.webp"),
  require("@/images/pearl/static-11.webp"),
  require("@/images/pearl/static-12.webp"),
  require("@/images/backgrounds/carpet-open-pearl.webp"),
  require("@/images/backgrounds/background-pearl-bonus.webp"),
];

export const silkWayImgs = [
  require("@/images/backgrounds/bg-silk-way.webp"),
  require("@/images/backgrounds/silk-way-bg-doubling-group.png"),
  require("@/images/backgrounds/silk-way-header.webp"),
  require("@/images/backgrounds/silk-way-mobile-bg.webp"),
  require("@/images/backgrounds/silk-way-mobile-bg.webp"),
  require("@/images/silk-way/anim-0.webp"),
  require("@/images/silk-way/anim-1.webp"),
  require("@/images/silk-way/anim-2.webp"),
  require("@/images/silk-way/anim-3.webp"),
  require("@/images/silk-way/anim-4.webp"),
  require("@/images/silk-way/anim-5.webp"),
  require("@/images/silk-way/anim-6.webp"),
  require("@/images/silk-way/anim-7.webp"),
  require("@/images/silk-way/anim-8.webp"),
  require("@/images/silk-way/anim-9.webp"),
  require("@/images/silk-way/anim-10.webp"),
  require("@/images/silk-way/anim-11.webp"),
  require("@/images/silk-way/anim-12.webp"),
  require("@/images/silk-way/static-0.webp"),
  require("@/images/silk-way/static-1.webp"),
  require("@/images/silk-way/static-2.webp"),
  require("@/images/silk-way/static-3.webp"),
  require("@/images/silk-way/static-4.webp"),
  require("@/images/silk-way/static-5.webp"),
  require("@/images/silk-way/static-6.webp"),
  require("@/images/silk-way/static-7.webp"),
  require("@/images/silk-way/static-8.webp"),
  require("@/images/silk-way/static-9.webp"),
  require("@/images/silk-way/static-10.webp"),
  require("@/images/silk-way/static-11.webp"),
  require("@/images/silk-way/static-12.webp"),
  require("@/images/backgrounds/carpet-open-silk-way.webp"),
  require("@/images/backgrounds/background-silk-way-bonus.webp"),
];

export const astroImgs = [
  require("@/images/backgrounds/bg-astrologer.webp"),
  require("@/images/backgrounds/bg-astro-mobile.webp"),
  require("@/images/astro/anim-0.webp"),
  require("@/images/astro/anim-1.webp"),
  require("@/images/astro/anim-2.webp"),
  require("@/images/astro/anim-3.webp"),
  require("@/images/astro/anim-4.webp"),
  require("@/images/astro/anim-5.webp"),
  require("@/images/astro/anim-6.webp"),
  require("@/images/astro/anim-7.webp"),
  require("@/images/astro/anim-8.webp"),
  require("@/images/astro/anim-9.webp"),
  require("@/images/astro/anim-10.webp"),
  require("@/images/astro/anim-11.webp"),
  require("@/images/astro/anim-12.webp"),
  require("@/images/astro/static-0.webp"),
  require("@/images/astro/static-1.webp"),
  require("@/images/astro/static-2.webp"),
  require("@/images/astro/static-3.webp"),
  require("@/images/astro/static-4.webp"),
  require("@/images/astro/static-5.webp"),
  require("@/images/astro/static-6.webp"),
  require("@/images/astro/static-7.webp"),
  require("@/images/astro/static-8.webp"),
  require("@/images/astro/static-9.webp"),
  require("@/images/astro/static-10.webp"),
  require("@/images/astro/static-11.webp"),
  require("@/images/astro/static-12.webp"),
  require("@/images/backgrounds/carpet-open-astrologer.webp"),
  require("@/images/backgrounds/background-astrologer-bonus.webp"),
];
