import { EventEmitter } from "tsee";
import { MessagePacket, MessagePacketTypes } from "@/types/packets";
import tickets from "@/api/tickets";
import {
  TransportAPI,
  TransportDisconnectCode,
  TransportEventMap,
  TransportState,
} from "./interface";
import { mockedFakeUserData } from "@/api/fake-data";
import { UpdateTicket } from "@/types/tickets";

const DEFAULT_ANSWER_TIME = 100;

let tempWon = 10000;

let tempOrder = 0;

export const randCoeff = () => Math.max(1.05, +(Math.random() * 5).toFixed(2));

export class BackSimulation extends EventEmitter<TransportEventMap> implements TransportAPI {
  private state: TransportState;

  constructor() {
    super();
    this.state = TransportState.DISCONNECTED;
    setTimeout(() => {
      this.state = TransportState.CONNECTED;
      this.emit("connect");
    }, 100);

    /*
       // setInterval(() => {
            // this.emulateNewNotification();
        }, 50000);
        */
  }

  public getState(): TransportState {
    return this.state;
  }

  public disconnect(): void {
    this.state = TransportState.DISCONNECTED;
    this.emit("disconnect", 4000);
  }

  public disconnectFixIos(code: TransportDisconnectCode) {
    this.state = TransportState.DISCONNECTED;
    this.emit("disconnect", TransportDisconnectCode.NO_HEARTBEAT);
  }

  protected onMakeTicket(id: number) {
    const draw = Math.round(Math.random() * 1000);
    setTimeout(() => {
      const payload: UpdateTicket = {
        ticket_id: 123,
        draw_id: draw,
        status: "accepted",
        amount: 100,
        payout: 0,
        currency: 972,
        received: new Date().getTime(),
        processed: new Date().getTime(),
        calculated: new Date().getTime(),
        draw: {
          id: draw,
          numbers: [],
        },
        payout_by_line: {
          lines_payout: [],
          bonus_payout: 0,
        },
        has_free_tickets: false,
        is_free_ticket: false,
        has_doubling: true,
      };
      this.onPacket({
        event: MessagePacketTypes.UPDATE_TICKET,
        data: payload,
      });
    }, 50);
    setTimeout(() => {
      const payload: UpdateTicket = tickets[tempOrder];
      this.onPacket({
        event: MessagePacketTypes.UPDATE_TICKET,
        data: payload,
      });
      tempOrder += 1;
    }, 290);
  }

  public send(packet: MessagePacket): void {
    console.debug("Sending packet to backend: ", packet);
    switch (packet.event) {
      case MessagePacketTypes.GET_USER_DATA:
        // return dummy after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: mockedFakeUserData,
            rid: packet.rid,
          });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.UPDATE_USER_DATA:
        // return empty after delay
        setTimeout(() => {
          Object.assign(mockedFakeUserData, packet.data);
          this.onPacket({ event: packet.event, data: { result: "OK" }, rid: packet.rid });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.RESTORE_AUTH:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({ event: packet.event, data: { result: "OK" }, rid: packet.rid });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.SIMPLE_AUTH:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({ event: packet.event, data: { result: "OK" }, rid: packet.rid });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.GET_SETTINGS:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: {
              combinations: ["combi-5", "combi-1", "combi-3", "combi-7", "combi-9"],
              chips: [
                { nominal: 10 },
                { nominal: 20 },
                { nominal: 30 },
                { nominal: 40 },
                { nominal: 50 },
                { nominal: 100 },
                { nominal: 200 },
                { nominal: 300 },
                { nominal: 400 },
                { nominal: 500 },
                { nominal: 1000 },
                { nominal: 1500 },
                { nominal: 2000 },
                { nominal: 2500 },
              ],
              doubling_game: {
                confirm_delay: 300000,
                playout_delay: 300000,
                enabled: true,
                split: {
                  enabled: true,
                  stage_number: 1,
                  min_amount: 10,
                },
                fireproof_stage: {
                  enabled: true,
                  game_number: 2,
                },
              },
              bonus_games: {
                enabled: true,
                num_games: 10,
                playout_delay: 10000,
                secondary_num_games: 10,
              },
              buy_in: {
                enabled: true,
                ticket_cost_multiplier: 7,
              },
            },
            rid: packet.rid,
          });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.GET_PROMO:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: {
              promo: {
                id: "57437987-4529-42c8-bf9c-33f8a8e2c783",
                name: "prom name",
                quantity: 2,
                combination: "combi-3",
                amount: 500,
                currency: 972,
              },
            },
            rid: packet.rid,
          });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.GET_LAST_DOUBLING_GAMES:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: [
              {
                id: 1234,
                status: "won",
                sign: "right",
                payout: 1234,
              },
              {
                id: 1235,
                status: "won",
                sign: "right",
                payout: 1234,
              },
              {
                id: 1236,
                status: "lost",
                sign: "right",
                payout: 1234,
              },
              {
                id: 1237,
                status: "won",
                sign: "right",
                payout: 1234,
              },
              {
                id: 1238,
                status: "lost",
                sign: "right",
                payout: 1234,
              },
              {
                id: 1239,
                status: "won",
                sign: "right",
                payout: 1234,
              },
            ],
            rid: packet.rid,
          });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.MAKE_TICKET:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: { ticket_id: 123, received: 124324212335252 },
            rid: packet.rid,
          });
          this.onMakeTicket(121212121212);
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.MAKE_BONUS_TICKET:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: { ticket_id: 123, received: 124324212335252 },
            rid: packet.rid,
          });
          this.onMakeTicket(121212121212);
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.DOUBLING_GAME:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: { result: "OK" },
            rid: packet.rid,
          });
        }, 100);
        break;
      case MessagePacketTypes.PLAY_DOUBLING_GAME:
        // return result: OK after delay
        tempWon *= 2;
        const result = Math.random() > 0.5 ? "won" : "lost";
        const payout = result === "won" ? tempWon : 0;
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: {
              doubling_game_id: 777,
              status: result,
              amount: 345,
              payout: payout,
              // payout: 0,
            },
            rid: packet.rid,
          });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.DRAW_HISTORY:
        // return result: OK after delay
        setTimeout(() => {
          this.onPacket({
            event: packet.event,
            data: {
              items: [
                {
                  id: 123454,
                  game_id: "loto-mevaho-5",
                  numbers: [6, 3, 3, 6, 5, 4, 3, 1, 4, 2, 3, 1, 2, 4, 4],
                  status: "played_out",
                  calculated: new Date().getTime(),
                },
                {
                  id: 123454,
                  game_id: "loto-mevaho-5",
                  numbers: [7, 7, 7, 7, 2, 1, 2, 5, 0, 2, 1, 0, 5, 7, 0],
                  status: "played_out",
                  calculated: new Date().getTime(),
                },
                {
                  id: 123454,
                  game_id: "loto-mevaho-5",
                  numbers: [7, 7, 7, 7, 2, 1, 2, 5, 0, 2, 1, 0, 5, 7, 0],
                  status: "played_out",
                  calculated: new Date().getTime(),
                },
                {
                  id: 123454,
                  game_id: "loto-mevaho-5",
                  numbers: [7, 7, 7, 7, 2, 1, 2, 5, 0, 2, 1, 0, 5, 7, 0],
                  status: "played_out",
                  calculated: new Date().getTime(),
                },
                {
                  id: 123454,
                  game_id: "loto-mevaho-5",
                  numbers: [7, 7, 7, 7, 2, 1, 2, 5, 0, 2, 1, 0, 5, 7, 0],
                  status: "played_out",
                  calculated: new Date().getTime(),
                },
                {
                  id: 123454,
                  game_id: "loto-mevaho-5",
                  numbers: [7, 7, 7, 7, 2, 1, 2, 5, 0, 2, 1, 0, 5, 7, 0],
                  status: "played_out",
                  calculated: new Date().getTime(),
                },
                {
                  id: 123454,
                  game_id: "loto-mevaho-5",
                  numbers: [9, 9, 9, 1, 2, 9, 9, 9, 1, 2, 9, 9, 0, 5, 2],
                  status: "played_out",
                  calculated: new Date().getTime(),
                },
              ],
              count: 100,
            },
            rid: packet.rid,
          });
        }, DEFAULT_ANSWER_TIME);
        break;
      case MessagePacketTypes.PING:
        setTimeout(() => {
          this.onPacket({ event: packet.event, data: {}, rid: packet.rid });
        }, DEFAULT_ANSWER_TIME);
        break;

      default:
        break;
    }
  }

  public reconnect(): void {
    this.state = TransportState.CONNECTED;
    this.emit("connect");
  }

  protected onPacket(packet: MessagePacket) {
    // add small error chance
    if (Math.floor(Math.random() * 10) > 9) {
      packet.error = { code: 42, text: "Server failure: I`l be back", critical: false };
    }
    console.debug("Receiving packet from backend: ", packet);
    this.emit("message", packet);
  }
}
