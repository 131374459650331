
import { defineComponent, PropType } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import DoublingHeader from "@/components/DoublingHeader.vue";
import InputNice from "@/components/UI/InputNice.vue";
import store from "@/store";
import DoublingBoxes from "@/components/DoublingBoxes.vue";
import DoublingBoxesSilkWay from "@/components/DoublingBoxesSilkWay.vue";
import DoublingBoxesMisr from "@/components/DoublingBoxesMisr.vue";
import DoublingBoxesPamir from "@/components/DoublingBoxesPamir.vue";
import DoublingBoxesAstrologer from "@/components/DoublingBoxesAstrologer.vue";
import ControlPanel from "@/components/Panels/ControlPanel.vue";
import LastBoxes from "@/components/LastBoxes.vue";
import InputFlame from "@/components/UI/InputFlame.vue";

export default defineComponent({
  name: "doubling-desktop",
  props: {},
  components: {
    InputFlame,
    ControlPanel,
    DoublingBoxes,
    InputNice,
    DoublingHeader,
    MenuWrapper,
    MainContainer,
    LastBoxes,
    DoublingBoxesSilkWay,
    DoublingBoxesMisr,
    DoublingBoxesPamir,
    DoublingBoxesAstrologer,
  },
  computed: {
    lastPayout(): number {
      return store.state.ui.lastPayout;
    },
    expectedWinnings(): number[] {
      return store.state.doubling.expectedWinnings;
    },
    stepCount(): number {
      return store.state.doubling.countGame;
    },
    isGambusakiGold(): boolean {
      return store.state.currentGameId === "loto-gambusaki-gold-9";
    },
    fireproof(): number | undefined {
      const fireproof = store.state.settings.doubling_game.fireproof_stage;
      return fireproof.enabled ? fireproof.game_number : undefined;
    },
    isKissaho(): boolean {
      return store.state.currentGameId === "loto-kissaho";
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
    isSilkWay(): boolean {
      return store.state.currentGameId === "loto-silk-way";
    },
    isMisr(): boolean {
      return store.state.currentGameId === "loto-misr";
    },

    isPamir(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    isAstro(): boolean {
      return store.state.currentGameId === "loto-astrologer";
    },
    isNoBird(): boolean {
      return (
        store.state.currentGameId === "loto-kissaho" ||
        store.state.currentGameId === "loto-silk-way" ||
        store.state.currentGameId === "loto-pearl" ||
        store.state.currentGameId === "loto-pamir-legends" ||
        store.state.currentGameId === "loto-astrologer" ||
        store.state.currentGameId === "loto-misr"
      );
    },
    isMan(): boolean {
      return (
        store.state.currentGameId === "loto-silk-way" ||
        store.state.currentGameId === "loto-pamir-legends" ||
        store.state.currentGameId === "loto-astrologer"
      );
    },
    imgMan(): string {
      return this.isGambusakiGold
        ? require("@/images/man-gold.webp")
        : this.isKissaho
        ? require("@/images/ui/man-kissaho.webp")
        : this.isPearl
        ? require("@/images/ui/man-pearl.webp")
        : this.isMisr
        ? require("@/images/ui/man-misr.webp")
        : /*  : this.isSilkWay
        ? require("@/images/ui/man-silk-way.webp")*/
          require("@/images/man.webp");
    },
  },
  methods: {},
});
